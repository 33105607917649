import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useRef, useState, useEffect } from "react";
import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Intro,SpecialMenu } from './container';
import { Navbar } from './components';
import Loader from './components/Loader/Loader.jsx'
import './App.css';

const App = () => {
  const ContainerRef = useRef(null);
  const [Loaded, setLoaded] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, []);

  return (
    <>
      <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence>
      <div ref={ContainerRef}>
      <AnimatePresence>
        {Loaded ? null : <Loader />}
        <Navbar key={"navbar"}/>
      <Header  key={"header"}/>
      <AboutUs key={'aboutus'} />
      <SpecialMenu key={'specialmenu'} />
      <Chef key={'chef'}/>
      <Intro  key={'intro'}/>
      <Gallery key={'gallery'}/>
      <FindUs key={'findus'}/>
      <Footer  key={'footer'}/>
     </AnimatePresence>
    </div>
    </>)
};

export default App;
