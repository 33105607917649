import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Chef's word" />
      <h1 className="headtext__cormorant">What we believe in</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">I believe that quality meat deserves quality treatment</p>
        </div>
        <p className="p__opensans">I created Farrier’s Steak House with the vision of bringing the best steaks to Šamorín and beyond.I believe that quality meat deserves quality treatment, from the farm to the table. That’s why I use only local and organic ingredients, and cook them with passion and skill on our charcoal grill. At Farrier’s, you will not only enjoy a delicious steak, but also a warm and friendly service, a cozy and elegant atmosphere, and a stunning view of the x-bionic® sphere resort and the Danube river. I invite you to join us and experience the art of fine dining.”</p>
      </div>

      <div className="app__chef-sign">
        <p>Peter Kotl</p>
        <p className="p__opensans">Chef & Founder</p>
        <img src={images.sign} alt="sign_image" />
      </div>
    </div>
  </div>
);

export default Chef;